<template>
    <div class="pb-20">
      <div class="mb-10">
        <h2 class="text-2xl font-bold">Tenures Configuration</h2>
      </div>
      <div class="border border-solid border-blue-200 pt-4">
        <datatable
          :ajax="true"
          :ajax-pagination="true"
          :url="`${$baseurl}/invest/admin/tenures/view`"
          :columns="tenures.columns"
          :query="tenures.query"
          :on-click="click"
          sort="tenures.created_at"
          order="asc"
          ref="table"
        >
        </datatable>
      </div>
      <modal
        class-name="flex flex-col w-full sm:max-w-500px"
        ref="tenureModal"
      >
        <h3 class="font-bold mb-2 text-xl">Update Tenure</h3>
        <p class="mb-10 text-gray-600">Update Days_{{ this.tenureDays }}</p>
        <form
          @submit.prevent="updateTenure"
          class="flex flex-col flex-grow"
        >
          <form-group
            v-model="tenuresForm.data.percentage.value"
            :form="tenuresForm"
            name="percentage"
            type="number"
          >
            Enter the percentage
          </form-group>
  
          <div class="flex items-center gap-4 mt-auto">
            <button
              type="button"
              class="btn btn-gray btn-md"
              @click.prevent="clearForm"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-blue btn-md w-full"
              :disabled="tenuresForm.loading"
            >
              <sm-loader-white v-if="tenuresForm.loading" />
              <span v-else>Update</span>
            </button>
          </div>
        </form>
      </modal>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return { tenuresForm: this.$options.basicForm([
        { name: 'percentage', rules: 'required' },
      ]),
        tenureDays: null,
        tenurePercentage: null,
        tenures: this.$options.resource([], {
          query: '',
          columns: [
            {
              name: 'days',
              th: 'Days',
              render: ({ days }) => days,
            },
            {
              name: 'percentage',
              th: 'Percentage',
              render: ({ percentage }) => percentage,
            },
          ],
        }),
      };
    },
  
    methods: {
      click(tenure) {
        if (tenure) {
          this.tenureId = tenure.id;
          this.tenureDays = tenure.days;
          this.tenurePercentage = tenure.percentage;
          this.$refs.tenureModal.open();
        }
      },
      clearForm() {
        this.tenuresForm = this.resetForm(this.tenuresForm);
        this.$refs.tenureModal.close();
      },
      async updateTenure() {
        if (!this.validateForm(this.tenuresForm)) return;
        this.tenuresForm.loading = true;
        this.tenuresForm.error = null;
        await this.$patch({
          url: `${this.$baseurl}/invest/admin/rates/modify-tenure-rate/${this.tenureId}`,
          headers: this.headers,
          data: {
            percentage: this.tenuresForm.data.percentage.value,
          },
          success: () => {
            this.$success({
              title: 'Operation Successful',
              body: `Days_${ this.tenureDays } Updated successfully.`,
              button: 'Okay',
            });
  
            this.$emit('success');
            this.reloadTable();
            this.clearForm();
          },
  
          error: (error) => {
            this.tenuresForm.error = error;
            this.mapFormErrors(
              this.tenuresForm,
              error.response?.data?.errors
            );
          },
        });
        this.tenuresForm.loading = false;
      },
      async reloadTable() {
        this.$refs.table?.loadAjaxData();
      },
    },
  };
  </script>
  